<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-danger mb-10" role="alert">
            {{ message }}
          </div>
          <Form
            @submit="handleSubmit"
            :validation-schema="schema"
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
          >
            <div class="mb-5">
              <Field
                type="text"
                placeholder="Username"
                name="username"
                class="form-control bg-transparent"
                v-model="formInput.username"
              />
              <ErrorMessage
                name="username"
                class="text-danger error-feedback"
              />
            </div>
            <div class="mb-5">
              <Field
                type="email"
                placeholder="Email"
                name="email"
                class="form-control bg-transparent"
                v-model="formInput.email"
              />
              <ErrorMessage name="email" class="text-danger error-feedback" />
            </div>
            <div class="mb-5">
              <Field
                type="password"
                placeholder="Password"
                name="password"
                class="form-control bg-transparent"
              />
              <ErrorMessage
                name="password"
                class="text-danger error-feedback"
              />
            </div>
            <div class="mb-5">
              <Field
                name="roles"
                as="select"
                class="form-control bg-transparent"
                multiple
              >
                <option value="" disabled>Select roles</option>
                <option
                  v-for="role in roles"
                  :key="role.id"
                  :value="role.name"
                  :selected="
                    formInput.roles && formInput.roles.includes(role.id)
                  "
                >
                  {{ role.name }}
                </option>
              </Field>
              <ErrorMessage name="roles" class="text-danger error-feedback" />
            </div>
            <div class="mb-5">
              <Field
                name="positions"
                as="select"
                class="form-control bg-transparent"
                multiple
              >
                <option value="" disabled>Select Jabatan</option>
                <option
                  v-for="(r, key) in positions"
                  :key="key"
                  :value="r.value"
                  :selected="
                    formInput.positions && formInput.positions.includes(r.value)
                  "
                >
                  {{ r.label }}
                </option>
              </Field>
              <ErrorMessage
                name="positions"
                class="text-danger error-feedback"
              />
            </div>

            <div>
              <button class="btn btn-secondary" @click="$router.go(-1)">
                Back
              </button>
              <button
                :disabled="loading"
                type="submit"
                id="kt_sign_in_submit"
                class="btn btn-success"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress" v-show="loading">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  >
                  </span>
                </span>
              </button>
            </div>
          </Form>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Service from "../../../services/base.service";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      email: yup.string().required("Email is required!"),
      password: yup.string().nullable(),
      roles: yup.array(),
      positions: yup.array(),
    });

    return {
      loading: false,
      message: "",
      formInput: {
        username: "",
        email: "",
        roles: [],
        positions: [],
      },
      roles: [],
      positions: [],
      schema,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    this.fetchRoleData();
    this.fetchPositon();
  },
  methods: {
    async fetchPositon() {
      try {
        const jabatan = new Service("master/jabatan");
        const { data } = await jabatan.getListData();
        this.positions = data.map((r) => {
          return { label: r.name, value: r.id };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("user");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.formInput = data;
        this.formInput.roles = data.roles.map((r) => r.id);
        this.formInput.positions = data.positions.map((r) => r.id);
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchRoleData() {
      this.loading = true;
      try {
        const BaseService = new Service("role");
        const { data } = await BaseService.getListData({ limit: 999999 });
        this.roles = data;
        // this.formInput = data;
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(data) {
      this.loading = true;
      try {
        const BaseService = new Service("user");
        const { message } = this.$route.params.id
          ? await BaseService.updateData(this.$route.params.id, data)
          : await BaseService.createData(data);
        this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
